body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root > .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
  top: 51px;
}

@keyframes animation-hand-appear {
    0% {
        opacity: 0;
        transform: scale(2);
        filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .25));
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25));
    }
}

@keyframes animation-hand-disappear {
    0% {
        transform: scale(1);
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25));
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(2);
        filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .25));
    }
}

@keyframes animation-hand-jump {
    0% {
        transform: scale(1);
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25));
    }
    40% {
        transform: scale(2);
        filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .25));
    }
    60% {
        transform: scale(2);
        filter: drop-shadow( 10px 10px 10px rgba(0, 0, 0, .25));
    }
    100% {
        transform: scale(1);
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25));
    }
}

@keyframes scale-up-center {
    0% {
        transform: scale(1);
        opacity: 0;
    }
    100% {
        transform: scale(2);
        opacity: 0.04;
    }
}
